<template>
  <div class="invoice-reached-due">
    <v-card class="mb-2">
      <v-card-title> Nota Jatuh Tempo</v-card-title>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="transactions"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'InvoiceReachedDue',

  data: () => ({
    search: '',

    headers: [
      { text: 'Customer', value: 'br_name' },
      { text: 'Kota', value: 'area' },
      { text: 'Referensi', value: 'reference' },
      { text: 'Tanggal Transaksi', value: 'tran_date' },
      { text: 'Jatuh Tempo', value: 'due_date' },
      { text: 'Sisa Piutang', value: 'remaining' },
    ],

    transactions: [],
    count: 0,

    loading: false,

    options: {},
  }),

  watch: {
    options: {
      handler() {
        this.getData()
      },

      deep: true,
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        search: this.search,
        sortBy: sortBy[0] ? sortBy[0] : '',
        sortDesc: sortDesc[0] ? sortDesc[0] : '',
        page: page,
        itemsPerPage: itemsPerPage,
      }

      this.$http.get('../notifications/list', { params }).then(({ data }) => {
        this.count = data.recordsTotal
        this.transactions = data.data
      })
    },
  },
}
</script>

<style scoped></style>
